<template>
  <v-btn color="indigo" dark="" class="mr-5 mb-2 mt-2" @click="makeShootingBookExcel()">
    Excel
    <v-icon right>mdi-microsoft-excel</v-icon>
  </v-btn>
</template>

<script>
import XLSX from 'sheetjs-style';

export default {
  name: 'shootingbookExcelExport',
  props: {
    excel_data: {
      type: Array
    },
    name: {
      type: String
    },
    club_user: {
      type: String
    }
  },
  data: () => ({}),
  computed: {
    comp_myShootingbook() {
      return this.excel_data
    }
  },
  methods: {
    makeShootingBookExcel() {

      let ExcelName = 'Schiessbuch von ' + this.name;
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      var Data = [];
      this.comp_myShootingbook.forEach(e => {
        var temp = [];
        if (this.club_user === 'club') {
          temp.push({
            'Name': e.name,
            'Vorname': e.vorname,
          });
        } else if (this.club_user === 'user') {
          temp.push({
            'Verein': e.comp_vereinname,
            'Aktivität': e.aktivitaet,
          });
        }

        temp.map(i => {
          i['Stand'] = e.KurzBez,
              i['Bahn'] = e.bahn,
              i['Standaufsicht'] = e.Aufsicht,
              i['Waffe'] = e.KurzT,
              i['Disziplin'] = e.LangBez

          if (this.club_user === 'user') {
            i['Schuss'] = e.tr_schuss
          }

          i['Eregbnis'] = e.trergges
          i['Beginn'] = new Date(e.beginn).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'}),
              i['Ende'] = new Date(e.ende).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
        });
        temp.map(i => {
          if (e.bestaetigung_holen === 1) {
            i['Freigabe durch Verein'] = new Date(e.bestaetigt_vereinDatum).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }
        })
        Data.push(...temp);
      })

      var shootingbookWS = XLSX.utils.json_to_sheet(Data);

      const CellOptions = {
        font: {
          name: 'Arial',
          sz: 10,
          bold: true,
          color: {rgb: "FFFFFFFF"}
        },
        fill: {
          bgColor: {rgb: "FF59AB44"},
          fgColor: {rgb: "FF59AB44"},
        },
        alignment: {
          wrapText: true
        },
      };

      // Hier wird die Range der Tabelle decodiert
      var range = XLSX.utils.decode_range(shootingbookWS['!ref']);
      // Hier wird herausgefunden, welche Adresse zu welchem Zellen/Spalten Namen gehört
      // Dann können Optionen an die entsprechende Zellen/Spalten gehängt werden.
      for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          // Zell Adressen für alle Zellen über alle Spalten
          // var cell_address = {c:C, r:R};
          // Zell Adressen nur in der 1 Zeile / Zeile ist r beginnend bei 0 / c ist die Spalte auch bei 0 beginnend.
          var cell_Header_Address = {c: C, r: 0};
          var cell_ref = XLSX.utils.encode_cell(cell_Header_Address);
          // Hänge an jede Spalte in Zeile 1 die Optionen dran.
          shootingbookWS[cell_ref].s = CellOptions;
        }
      }

      XLSX.utils.book_append_sheet(wb, shootingbookWS, 'schuetzenbuch') // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, ExcelName + '.xlsx') // name of the file is 'book.xlsx'

    }
  },
}
</script>

<style scoped>

</style>