<template>
  <div class="mt-4  px-5">

    <h2>Schiessbuch</h2>

    <v-alert
        dense
        text
        type="success">
      <b>Tipp:</b>
      <div>
        Hier werden alle beendeten Einträge von euren Schützen angezeigt. <br>

        <br><i class="fas fa-user-graduate"></i> Euer Schuetzenbuch könnt Ihr exportieren, falls Ihr es mal bei einem
        Amt vorlegen möchtet.
      </div>
    </v-alert>

    <v-data-table
        :headers="headers"
        :items="comp_Shootingbook"
        sort-by="nummer"
        class="elevation-1"
        ref="Shootingbook"
        :item-class="itemRowClass"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <shootingbook-p-d-f-export
              :pdf_data="comp_Shootingbook"
              :name="comp_ClubName"
              club_user="club"
          ></shootingbook-p-d-f-export>

          <shootingbook-excel-export
              :excel_data="comp_Shootingbook"
              :name="comp_ClubName"
              club_user="club"
          ></shootingbook-excel-export>
          <v-btn icon
                 @click="getMyShootingbook">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }}</span>
      </template>
      <template v-slot:item.ende="{ item }">
        <span>{{
            new Date(item.ende.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }}</span>
      </template>


      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          neu laden
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import shootingbookExcelExport from '@/views/component/shootingbookExcelExport';
import ShootingbookPDFExport from "@/views/component/shootingbookPDFExport";

export default {
  name: 'shootingbook',
  components: {ShootingbookPDFExport, shootingbookExcelExport},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Funktion', value: 'Aufsicht'},
      {text: 'Waffe - Kurztext', value: 'KurzT'},
      {text: 'Disziplin', value: 'LangBez'},
      {text: 'Ergebnis', value: 'trergges'},
      {text: 'Begin', value: 'beginn'},
      {text: 'Ende', value: 'ende'},
    ],

  }),

  computed: {
    comp_Shootingbook() {
      return this.$store.state.ClubApp.Shootingbook;
    },
    comp_ClubName() {
      const club = this.$store.getters['ClubAuth/isClubName'];
      const userClub = this.$store.state.UserAuth.defClub;
      var data = null;
      data = ''
      if (typeof club !== 'undefined') {
        data = club;
      } else if (userClub !== null) {
        data = userClub;
      }
      return data
    }

  },

  mounted() {
    this.getMyShootingbook();
  },

  methods: {

    getMyShootingbook() {
      this.$store.dispatch('ClubApp/getShootingbook')
    },
    initialize() {
      this.getMyShootingbook();
    },
    itemRowClass(item) {
      var Class = null;
      if (item.bestaetigt_vereinDatum !== null) {
        Class = 'green lighten-4'
      } else if (item.bestaetigung_holen === 1) {
        Class = 'amber lighten-4'
      } else {
        Class = ''
      }
      return Class;
    },
  },
}
</script>

<style scoped>


</style>

